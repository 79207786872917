"use client";

import { VotingStatus } from "@/lib/ontrade";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { usePostHog } from "posthog-js/react";
import { usePredictionCard } from "./PredictionCardProvider";
interface HomePredictionVoteButtonProps {
  userLoggedIn: boolean;
  buttonText: string;
  voteType: "YES" | "NO";
  className?: string;
  votingStatus: VotingStatus;
  voteFrom: "modal" | "card";
}
export default function HomePredictionVoteButton({
  userLoggedIn,
  buttonText,
  voteType,
  className,
  votingStatus,
  voteFrom
}: HomePredictionVoteButtonProps) {
  const {
    isVoting,
    voteProposition,
    proposition
  } = usePredictionCard();
  const posthog = usePostHog();
  const vote = async () => {
    if (!userLoggedIn || votingStatus !== "ONGOING") {
      return;
    }
    await voteProposition(voteType);
    posthog.capture("Voted on proposition", {
      proposition_id: proposition.id,
      vote_type: voteType,
      vote_from: voteFrom
    });
  };
  return <Button variant="outlineThickBottom" className={cn("text-base font-bold text-center", className)} size="sm" onClick={vote} disabled={isVoting} data-sentry-element="Button" data-sentry-component="HomePredictionVoteButton" data-sentry-source-file="HomePredictionVoteButton.tsx">
      {buttonText}
    </Button>;
}
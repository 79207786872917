// WIP to make the gauge look generic
"use client";

import { getDialColor } from "./ScenarioCard";
export const Gauge = ({
  value,
  showExtremes = false
}: {
  value: number;
  showExtremes?: boolean;
}) => {
  // Clamp value between 0 and 100
  const clampedValue = Math.max(0, Math.min(value, 100));

  // Define arc radius
  const radius = 40;
  // Arc length of a semicircle: π * radius
  const arcLength = Math.PI * radius;
  // Compute dash offset so that the filled portion is proportional to the value
  const dashOffset = arcLength - clampedValue / 100 * arcLength;

  // Path definition for a top semicircle:
  // Starts at (10,50) and ends at (90,50) with a radius of 40
  // This creates an arc covering the top half of a circle centered at (50,50)
  const pathDefinition = `M 10,50 A ${radius},${radius} 0 0 1 90,50`;
  const clampedValuedRoundedOff = Math.round(value);
  let clampedValueDisplayed = `${clampedValuedRoundedOff}%`;
  if (clampedValuedRoundedOff === 0 && !showExtremes) {
    clampedValueDisplayed = "<1%";
  } else if (clampedValuedRoundedOff === 100 && !showExtremes) {
    clampedValueDisplayed = ">99%";
  }
  const dialColor = getDialColor(clampedValue / 100, "light");
  return <div className="relative items-center" data-sentry-component="Gauge" data-sentry-source-file="Gauge.tsx">
      {/* hidden div to show the background color */}
      <div className="hidden text-[#20C502B3]" />
      <svg viewBox="0 0 100 60" className="w-full max-w-xs h-auto" data-sentry-element="svg" data-sentry-source-file="Gauge.tsx">
        {/* Background semicircular arc */}
        <path d={pathDefinition} fill="transparent" stroke="currentColor" strokeWidth="14" className="text-gray-200" data-sentry-element="path" data-sentry-source-file="Gauge.tsx" />
        {/* Filled portion of the arc */}
        <path d={pathDefinition} fill="transparent" stroke="currentColor" strokeWidth="14" strokeDasharray={arcLength} strokeDashoffset={dashOffset} style={{
        transition: "stroke-dashoffset 0.35s ease",
        color: dialColor
      }} data-sentry-element="path" data-sentry-source-file="Gauge.tsx" />
      </svg>
      {/* Overlayed percentage text */}
      <div className="absolute inset-0 flex items-end justify-center pointer-events-none">
        <span className="text-sm font-semibold mt-2 text-center">
          {clampedValueDisplayed}
        </span>
      </div>
    </div>;
};
"use client";

import { VotingStatus } from "@/lib/ontrade";
import HomePredictionVoteButton from "./HomePredictionVoteButton";
import { cn } from "@/lib/utils";
type VoteSectionProps = {
  userLoggedIn: boolean;
  className?: string;
  labelClassName?: string;
  buttonClassName?: string;
  votingStatus: VotingStatus;
  totalVotes?: number;
  voteFrom: "modal" | "card";
};
const PredictionCardVoting = ({
  userLoggedIn,
  className,
  labelClassName,
  buttonClassName,
  votingStatus,
  totalVotes,
  voteFrom
}: VoteSectionProps) => {
  return <div className={cn("flex-1 flex flex-col items-center justify-start gap-4 p-2", className)} data-sentry-component="PredictionCardVoting" data-sentry-source-file="PredictionCardVoting.tsx">
      <div className="flex flex-col items-center justify-center gap-1">
        <p className={cn("text-sm text-center", labelClassName)}>
          Add your vote
        </p>
        <p className={cn("text-xs text-muted-foreground", totalVotes ? "flex" : "hidden")}>
          {totalVotes} {totalVotes === 1 ? "vote" : "votes"}
        </p>
      </div>
      <div className="flex flex-row gap-2">
        <HomePredictionVoteButton userLoggedIn={userLoggedIn} buttonText="Yes" voteType="YES" className={cn("w-11 h-11 border-[#18A800] text-center  text-[#18A800]", buttonClassName)} votingStatus={votingStatus} voteFrom={voteFrom} data-sentry-element="HomePredictionVoteButton" data-sentry-source-file="PredictionCardVoting.tsx" />
        <HomePredictionVoteButton userLoggedIn={userLoggedIn} buttonText="No" voteType="NO" className={cn("w-11 h-11 border-[#E00F2F] text-center text-[#E00F2F]", buttonClassName)} votingStatus={votingStatus} voteFrom={voteFrom} data-sentry-element="HomePredictionVoteButton" data-sentry-source-file="PredictionCardVoting.tsx" />
      </div>
    </div>;
};
export default PredictionCardVoting;
"use client";

import { LineChart } from "@/components/charts/LineChart";
import dayjs from "dayjs";
import { Proposition, UserPrediction, Prediction, OnTradePrediction, PredictionMethodologyKey } from "@/lib/ontrade";
import PredictionCardVoting from "./PredictionCardVoting";
import GaugeWithDetails from "./GaugeWithDetails";
import { cn } from "@/lib/utils";
import { random } from "lodash";
const methodology = "We analyze the options market -- where traders risk money on an exact price at a set date -- to estimate future stock prices. By assessing option prices across strikes and expirations, we construct a probability distribution of outcomes. These prices adjust to new data, offering a real-time sentiment gauge. The model translates market prices into implied probabilities, producing a forecast driven solely by actual market bets.";
const graphDescription = "The graph shows how our probability model changes over time, alongside the community voting sentiment.";
type MethodologyMap = { [K in PredictionMethodologyKey["target"]]: {
  description: string;
} };
const predictionDescription: MethodologyMap = {
  analystestimate: {
    description: "This is the probability that the stock will achieve the highest analyst estimate price."
  },
  "52weekhigh": {
    description: "This is the probability that the stock will achieve the highest price in the last 52 weeks."
  },
  percentgain: {
    description: "This is the probability that the stock will exceed 5% gain from the beginning of the period."
  }
};
type GraphDataPoint = {
  at: Date;
  yesPercentage: number;
};
function computeCumulativeGraphData(userPredictions: UserPrediction[]): GraphDataPoint[] {
  // Sort predictions chronologically
  const sortedPredictions = userPredictions.sort((a, b) => new Date(a.predicted_at).getTime() - new Date(b.predicted_at).getTime());
  let cumulativeYes = 0;
  let cumulativeTotal = 0;
  const dataPoints: GraphDataPoint[] = [];

  // Process each prediction in chronological order
  for (const prediction of sortedPredictions) {
    cumulativeTotal += 1;
    if (prediction.prediction === "YES") {
      cumulativeYes += 1;
    }
    dataPoints.push({
      at: new Date(prediction.predicted_at),
      yesPercentage: cumulativeYes / cumulativeTotal * 100
    });
  }
  return dataPoints;
}
interface ChartData {
  at: Date; // formatted date string (or you could keep it as Date)
  Community: number | null; // convert "YES" to 1 and "NO" to 0, for example
  OnTrade: number | null; // the numeric prediction
}

// Helper function to find the last value before a given date
const findLastValue = <T extends OnTradePrediction | GraphDataPoint,>(arr: T[], date: Date, getValue: (item: T) => number): number | null => {
  const timestamp = date.getTime();
  for (let i = arr.length - 1; i >= 0; i--) {
    let itemDate: number | null = null;
    const item = arr[i];
    if ("at" in item) {
      itemDate = dayjs(item.at).valueOf();
    }
    if (itemDate && itemDate <= timestamp) {
      return getValue(item);
    }
  }
  return null;
};
function mergeChartData(likelihood_history: OnTradePrediction[] = [], cumulativeGraphData: GraphDataPoint[] = [], startDate: Date, endDate: Date): ChartData[] {
  const noData = likelihood_history.length === 0 && cumulativeGraphData.length === 0;
  if (noData) {
    return [{
      at: startDate,
      Community: null,
      OnTrade: null
    }, {
      at: endDate,
      Community: null,
      OnTrade: null
    }];
  }

  // Sort input arrays chronologically
  const sortedLikelihood = likelihood_history.sort((a, b) => dayjs(a.at).valueOf() - dayjs(b.at).valueOf());
  const sortedCommunity = cumulativeGraphData.sort((a, b) => dayjs(a.at).valueOf() - dayjs(b.at).valueOf());
  const lastLikelihoodComputedAt = sortedLikelihood[sortedLikelihood.length - 1]?.at;
  const lastCommunityAt = sortedCommunity[sortedCommunity.length - 1]?.at;
  // The last date on which we have either a likelihood or a community prediction
  const lastDate = Math.max(lastLikelihoodComputedAt ? new Date(lastLikelihoodComputedAt).getTime() : 0, lastCommunityAt ? lastCommunityAt.getTime() : 0);
  const end = new Date(lastDate);

  // Create a map to store all data points
  const map: Record<string, ChartData> = {};
  const currentDate = new Date(startDate);
  // Fill in daily intervals
  while (currentDate <= end) {
    // Start of day
    const startOfDay = new Date(currentDate.setHours(0, 0, 0, 0));
    const startKey = startOfDay.toISOString();
    map[startKey] = {
      at: startOfDay,
      OnTrade: findLastValue(sortedLikelihood, startOfDay, item => item.percentage) ?? sortedLikelihood[0]?.percentage ?? null,
      Community: findLastValue(sortedCommunity, startOfDay, item => item.yesPercentage) ?? 0
    };

    // End of day
    const endOfDay = new Date(currentDate.setHours(23, 59, 59, 999));
    const endKey = endOfDay.toISOString();
    map[endKey] = {
      at: endOfDay,
      OnTrade: findLastValue(sortedLikelihood, endOfDay, item => item.percentage) ?? sortedLikelihood[0]?.percentage ?? null,
      Community: findLastValue(sortedCommunity, endOfDay, item => item.yesPercentage) ?? 0
    };

    // Move to next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Add actual data points
  sortedLikelihood.forEach(item => {
    const key = item.at;
    if (!map[key]) {
      map[key] = {
        at: new Date(key),
        Community: findLastValue(sortedCommunity, new Date(key), item => item.yesPercentage) ?? 0,
        OnTrade: item.percentage
      };
    } else {
      map[key].OnTrade = item.percentage;
      if (map[key].Community === null) {
        map[key].Community = findLastValue(sortedCommunity, new Date(key), item => item.yesPercentage) ?? 0;
      }
    }
  });
  sortedCommunity.forEach(item => {
    const key = item.at.toISOString();
    if (!map[key]) {
      map[key] = {
        at: new Date(key),
        Community: item.yesPercentage,
        OnTrade: findLastValue(sortedLikelihood, item.at, item => item.percentage) ?? sortedLikelihood[0]?.percentage ?? null
      };
    } else {
      map[key].at = item.at;
      map[key].Community = item.yesPercentage;
      if (map[key].OnTrade === null) {
        map[key].OnTrade = findLastValue(sortedLikelihood, item.at, item => item.percentage) ?? sortedLikelihood[0]?.percentage ?? null;
      }
    }
  });

  // Fill in missing dates with null values from lastDate to endDate
  const lastDateDate = new Date(lastDate);
  const endDateDate = new Date(endDate);

  // Add only two points: the last data point and the end date
  const key = lastDateDate.toISOString();
  if (!map[key]) {
    map[key] = {
      at: lastDateDate,
      Community: findLastValue(sortedCommunity, lastDateDate, item => item.yesPercentage) ?? 0,
      OnTrade: findLastValue(sortedLikelihood, lastDateDate, item => item.percentage)
    };
  }

  // Add the end date point
  const endKey = endDateDate.toISOString();
  if (!map[endKey]) {
    map[endKey] = {
      at: endDateDate,
      Community: null,
      OnTrade: null
    };
  }
  return Object.values(map).sort((a, b) => a.at.getTime() - b.at.getTime());
}
export const PredictionCardGraph = ({
  prediction,
  proposition,
  userLoggedIn
}: {
  prediction: Prediction;
  proposition: Proposition;
  userLoggedIn: boolean;
}) => {
  const {
    likelihood,
    prediction_history,
    user_predicted_value,
    num_votes_no,
    num_votes_yes
  } = prediction;
  const {
    proposition_hero_text,
    proposition_prefix,
    proposition_suffix,
    voting_status,
    voting_stopped_at,
    proposition_outcome,
    voting_starts_at,
    voting_ends_at,
    presentation_config
  } = proposition;
  const cumulativeGraphData = computeCumulativeGraphData(prediction_history);
  const startChartDataPoint = new Date(voting_starts_at);
  const endChartDataPoint = new Date(voting_ends_at);
  const data = mergeChartData(likelihood.likelihood_history, cumulativeGraphData, startChartDataPoint, endChartDataPoint);
  const hasVotes = num_votes_yes > 0 || num_votes_no > 0;
  // prevent division by 0
  const communityGaugeValue = hasVotes ? num_votes_yes / (num_votes_yes + num_votes_no) * 100 : 0;
  const ontradeGaugeValue = likelihood.overall_likelihood ?? 0;
  const isPropositionProven = proposition_outcome === "PROVEN_RIGHT" || proposition_outcome === "PROVEN_WRONG";
  const showEndState = isPropositionProven;
  const isVotingEnded = voting_status === "CANCELLED" || voting_status === "COMPLETED";
  const propositionProvenRight = isPropositionProven && proposition_outcome === "PROVEN_RIGHT";
  const propositionProvenWrong = isPropositionProven && proposition_outcome === "PROVEN_WRONG";
  const updatedData = data.map(item => ({
    ...item,
    at: item.at.getTime()
  }));
  const totalVotes = num_votes_yes + num_votes_no;
  return <div className="flex flex-col" data-sentry-component="PredictionCardGraph" data-sentry-source-file="PredictionCardGraph.tsx">
      <h3 className="text-2xl font-bold">
        {proposition_prefix} {proposition_hero_text} {proposition_suffix}
      </h3>
      {/* Show user predicted value if it exists */}
      <div className={cn("flex flex-row items-center justify-center p-2 rounded-lg text-white m-2", propositionProvenRight ? "bg-[#18A800]" : "", propositionProvenWrong ? "bg-[#E00F2F]" : "", showEndState ? "flex" : "hidden")}>
        <p className="text-sm md:text-sm font-semibold mr-1">
          {propositionProvenRight ? "YES" : "NO"}:
        </p>
        {isPropositionProven && <p className="text-sm md:text-sm">
            {propositionProvenRight ? "Prediction fullfilled on" : "Prediction failed on"}{" "}
            {dayjs(voting_stopped_at).format("MMMM DD, YYYY")}
          </p>}
        {!isPropositionProven && <p className="text-sm md:text-sm">Prediction not proven yet</p>}
      </div>
      {/* Show base price and target price if they exist */}
      <div className="flex flex-row justify-between items-center my-3">
        <p className="text-xs">
          Prediction date: {dayjs(voting_starts_at).format("M/D/YY")} ($
          {proposition.data.entry_price ?? "N/A"} price)
        </p>
        <p className="text-xs">
          Resolves on or before:{" "}
          {dayjs(proposition?.data?.target_date ?? voting_ends_at).format("M/D/YY")}{" "}
          (Target price: ${proposition?.data.target_price ?? "N/A"})
        </p>
      </div>
      <LineChart className="h-64 my-2" data={updatedData} index="at" colors={["secondary", "orange"]} categories={["OnTrade", "Community"]} showLegend={false} showTooltip={true} valueFormatter={value => `${Number(value.toFixed(2))}%`} xTickFormatter={value => dayjs(value).format("MMM DD")} minValue={0} maxValue={100} xAxisDomain={[startChartDataPoint.getTime(), endChartDataPoint.getTime()]} intervalType="preserveStartEnd" xAxisOverridingProps={{
      scale: "auto",
      type: "number"
    }} lineStrokeWidth={3} data-sentry-element="LineChart" data-sentry-source-file="PredictionCardGraph.tsx"></LineChart>
      <div className="flex flex-row items-center flex-wrap m-4 gap-2 justify-center">
        <div className="w-2 h-2 md:w-4 md:h-4 bg-secondary rounded-xs md:rounded-sm"></div>
        <p className="text-sm md:text-lg">OnTrade</p>
        <p className="text-sm md:text-lg">vs</p>
        <div className="w-2 h-2 md:w-4 md:h-4 bg-orange-500 rounded-xs md:rounded-sm"></div>
        <p className="text-sm md:text-lg">Community</p>
      </div>
      <div className="flex flex-row w-full p-2 bg-secondary/10 items-center justify-between rounded-lg md:min-h-20">
        <GaugeWithDetails gaugeValue={ontradeGaugeValue} gaugeLabel="OnTrade Likelihood" className="flex-none flex-col md:flex-row basis-1/2 justify-start" gaugeLabelClassName="md:font-bold mr-2" data-sentry-element="GaugeWithDetails" data-sentry-source-file="PredictionCardGraph.tsx" />
        <div className="border-l border-gray-300 h-full min-h-12"></div>
        {(user_predicted_value === null || user_predicted_value === undefined) && !isVotingEnded && !isPropositionProven ? <PredictionCardVoting userLoggedIn={userLoggedIn} className="flex-none flex-col md:flex-row basis-1/2 justify-end" labelClassName="md:font-bold mr-2" buttonClassName="w-26 h-11" votingStatus={voting_status} totalVotes={totalVotes} voteFrom="modal" /> : <GaugeWithDetails gaugeValue={communityGaugeValue} gaugeLabel="Community" className="flex-none flex-col md:flex-row basis-1/2" gaugeLabelClassName="md:font-bold mr-2" gaugeSubText={`${totalVotes} ${totalVotes === 1 ? "vote" : "votes"}`} gaugeFooter={isVotingEnded || isPropositionProven ? "Voting has ended" : ""} showExtremes={true} />}
      </div>
      <div className="mt-4">
        <h2 className="text-lg font-normal mb-2">Prediction description</h2>
        <p className="text-sm">
          {predictionDescription[presentation_config.methodology.target].description}
        </p>
      </div>
      <div className="mt-4">
        <h2 className="text-lg font-normal mb-2">Graph description</h2>
        <p className="text-sm">{graphDescription}</p>
      </div>
      <div className="mt-4">
        <h2 className="text-lg font-normal mb-2">Methodology</h2>
        <p className="text-sm">{methodology}</p>
      </div>
    </div>;
};
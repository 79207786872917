"use client";

import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { inputClassName } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { availableTickers, cn } from "@/lib/utils";
import { trpc } from "@/providers/TrpcProvider";
import { zodResolver } from "@hookform/resolvers/zod";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import { CurrentPriceProvider, useCurrentPrice } from "./CurrentPriceProvider";
const formSchema = z.object({
  ticker: z.string().min(1, "Please select a ticker"),
  rsu: z.string().refine(val => !isNaN(Number(val)) && Number(val) >= 1, {
    message: "RSU must be a number greater than or equal to 1"
  }),
  rsuInputType: z.enum(["dollars", "shares"]),
  rsuValue: z.string().refine(val => !isNaN(Number(val)), {
    message: "RSU value must be a number"
  })
});
type FormValues = z.infer<typeof formSchema>;
interface AnonUserFormProps {
  defaultTicker?: string;
  defaultRsu?: string;
  setTicker?: (ticker: string) => void;
}
export default function WrappedAnonUserForm({
  defaultTicker = "AMZN",
  defaultRsu
}: AnonUserFormProps) {
  const [ticker, setTicker] = useState(defaultTicker);
  return <CurrentPriceProvider tickerToQuery={ticker} enabled data-sentry-element="CurrentPriceProvider" data-sentry-component="WrappedAnonUserForm" data-sentry-source-file="AnonUserForm.tsx">
      <AnonUserForm defaultTicker={defaultTicker} defaultRsu={defaultRsu} setTicker={setTicker} data-sentry-element="AnonUserForm" data-sentry-source-file="AnonUserForm.tsx" />
    </CurrentPriceProvider>;
}
function AnonUserForm({
  defaultTicker = "AMZN",
  defaultRsu,
  setTicker
}: AnonUserFormProps) {
  const router = useRouter();
  const {
    currentPrice,
    isLoading
  } = useCurrentPrice();
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ticker: defaultTicker,
      rsu: defaultRsu || "100",
      rsuValue: `${defaultRsu ? +(defaultRsu || 100) * currentPrice : "1000"}`,
      rsuInputType: "dollars"
    }
  });
  const ticker = form.watch("ticker");
  useEffect(() => {
    if (setTicker) setTicker(ticker);
  }, [ticker, setTicker]);
  const {
    mutateAsync: saveAnonParameters
  } = trpc.users.saveAnonParameters.useMutation();
  const onSubmit = async (data: FormValues) => {
    try {
      const rsuFromDollars = Math.floor(+(data.rsuValue || 0) / currentPrice);
      if (form.watch("rsuInputType") === "dollars" && rsuFromDollars < 1) {
        toast.error("Value must be at least $1");
        return;
      }
      await saveAnonParameters({
        ticker: data.ticker,
        rsu: form.watch("rsuInputType") === "dollars" ? rsuFromDollars : parseInt(data.rsu)
      });
      toast.success("Parameters saved successfully");
      router.replace(`/?ticker=${data.ticker}`);
    } catch (error) {
      toast.error("Failed to save parameters");
      console.error(error);
    }
  };
  return <div className="flex flex-col gap-6 items-center py-12 px-4 h-[calc(100vh-64px)] relative overflow-hidden z-10" data-sentry-component="AnonUserForm" data-sentry-source-file="AnonUserForm.tsx">
      <div className="flex flex-col gap-2">
        <h1 className="text-3xl md:text-5xl font-bold text-center text-primary">
          Your stocks deserve better
        </h1>
      </div>
      <div className="flex flex-col gap-6">
        <Form {...form} data-sentry-element="Form" data-sentry-source-file="AnonUserForm.tsx">
          <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-6">
            <FormField control={form.control} name="ticker" render={({
            field
          }) => <FormItem>
                  <FormLabel>Choose your stock</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger className="h-12 bg-white w-[360px] max-w-full">
                        <SelectValue placeholder="Select ticker" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {availableTickers.map(ticker => <SelectItem key={ticker.ticker} value={ticker.ticker}>
                          {ticker.displayName} ({ticker.ticker})
                        </SelectItem>)}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>} data-sentry-element="FormField" data-sentry-source-file="AnonUserForm.tsx" />

            <div className="flex flex-col gap-2">
              <FormField control={form.control} name="rsuInputType" render={({
              field
            }) => <FormItem>
                    <FormControl>
                      <div className="flex flex-row items-center justify-center gap-1">
                        <Label htmlFor="valueMode" className="text-xs">
                          Dollars
                        </Label>
                        <Switch id="valueMode" checked={field.value !== "dollars"} onCheckedChange={() => {
                    field.onChange(field.value !== "dollars" ? "dollars" : "shares");
                  }} className="border border-muted data-[state=unchecked]:bg-primary h-6 w-10 pl-0.5" />
                        <Label htmlFor="valueMode" className="text-xs">
                          Shares
                        </Label>
                      </div>
                    </FormControl>
                  </FormItem>} data-sentry-element="FormField" data-sentry-source-file="AnonUserForm.tsx" />

              <FormField control={form.control} name={form.watch("rsuInputType") === "dollars" ? "rsuValue" : "rsu"} render={({
              field
            }) => <FormItem>
                    <FormLabel>
                      {form.watch("rsuInputType") === "dollars" ? `Value of all your ${form.watch("ticker")} shares (including unvested)` : `Number of all your ${form.watch("ticker")} shares (including unvested)`}
                    </FormLabel>
                    <FormControl>
                      <CurrencyInput className={cn("w-full", inputClassName, "h-12 bg-white w-[360px] max-w-full")}
                // {...field}
                onValueChange={(value, name, values) => {
                  form.setValue("rsuValue", `${values?.float || 0}`);
                  form.setValue("rsu", `${values?.float || 0}`);
                }} value={form.watch("rsuInputType") === "dollars" ? form.watch("rsuValue") : form.watch("rsu")}
                // defaultValue={field.value}
                prefix={form.watch("rsuInputType") === "dollars" ? "$" : ""} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>} data-sentry-element="FormField" data-sentry-source-file="AnonUserForm.tsx" />
              <p className="text-xs text-muted-foreground text-right mt-1">
                {isLoading || !currentPrice ? "Calculating..." : form.watch("rsuInputType") === "dollars" ? `Approximately ${Math.floor(+form.watch("rsuValue") / currentPrice)} shares` : `Approximately ${Math.floor(+form.watch("rsu") * currentPrice).toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}`}
              </p>
            </div>

            <Button type="submit" variant="default" className="mt-4" disabled={form.watch("rsuInputType") === "dollars" && +form.watch("rsuValue") < 1} data-sentry-element="Button" data-sentry-source-file="AnonUserForm.tsx">
              See your dashboard
            </Button>
          </form>
        </Form>
      </div>
      <div className={cn("absolute overflow-hidden -z-10 h-full aspect-square", "lg:aspect-video transition-all duration-300", "bottom-[-50%] left-[-100%]",
    // mobile
    "sm:bottom-[-30%] sm:left-[-25%]",
    // tablet
    "md:bottom-[-30%] md:left-[0%]",
    // tablet
    "lg:bottom-[-30%] lg:left-[-25%]",
    // desktop
    "xl:bottom-[-40%] xl:left-[-10%]" // desktop
    )} style={{
      position: "absolute",
      transform: "perspective(820px) rotate3d(40, -16, 18, 60deg)",
      boxShadow: "0px 12px 36px 0px rgba(0, 52,112,0.50)"
    }}>
        <Image src="/dashboard-preview.png" alt="OnTrade AI Dashboard Preview" fill className="aspect-square" style={{
        objectFit: "cover",
        objectPosition: "top left"
      }} data-sentry-element="Image" data-sentry-source-file="AnonUserForm.tsx" />
      </div>
    </div>;
}
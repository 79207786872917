import { Gauge } from "./Gauge";
import { cn } from "@/lib/utils";
type GaugeWithDetailsProps = {
  gaugeValue: number;
  gaugeLabel: string;
  gaugeFooter?: string;
  className?: string;
  gaugeLabelClassName?: string;
  gaugeSubText?: string;
  showExtremes?: boolean;
};
const GaugeWithDetails = ({
  gaugeValue,
  gaugeLabel,
  gaugeFooter,
  className,
  gaugeLabelClassName,
  gaugeSubText,
  showExtremes = false
}: GaugeWithDetailsProps) => {
  return <div className={cn("flex-1 flex flex-col items-center justify-center px-4", className)} data-sentry-component="GaugeWithDetails" data-sentry-source-file="GaugeWithDetails.tsx">
      <div className="flex flex-col items-center justify-center gap-1">
        <p className={cn("text-sm", gaugeLabelClassName)}>{gaugeLabel}</p>
        <p className={cn("text-xs text-muted-foreground", gaugeSubText ? "flex" : "hidden")}>
          {gaugeSubText}
        </p>
      </div>
      <div className="max-w-[5rem]">
        <Gauge value={gaugeValue} showExtremes={showExtremes} data-sentry-element="Gauge" data-sentry-source-file="GaugeWithDetails.tsx" />
      </div>
      {gaugeFooter && <p className="text-xs text-center text-gray-500">{gaugeFooter}</p>}
    </div>;
};
export default GaugeWithDetails;
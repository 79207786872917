"use client";

import { createContext, useContext, useState, ReactNode } from "react";
import { Proposition, Prediction } from "@/lib/ontrade";
import { trpc } from "@/providers/TrpcProvider";
interface PredictionCardContextType {
  proposition: Proposition;
  prediction: Prediction;
  userLoggedIn: boolean;
  isVoting: boolean;
  voteProposition: (vote: "YES" | "NO") => Promise<void>;
  updatePrediction: (newPrediction: Partial<Prediction>) => void;
}
const PredictionCardContext = createContext<PredictionCardContextType | undefined>(undefined);
export function PredictionCardProvider({
  children,
  initialProposition,
  initialPrediction,
  userLoggedIn
}: {
  children: ReactNode;
  initialProposition: Proposition;
  initialPrediction: Prediction;
  userLoggedIn: boolean;
}) {
  const [proposition, setProposition] = useState(initialProposition);
  const [prediction, setPrediction] = useState(initialPrediction);
  const {
    mutateAsync: voteOnProposition,
    isPending: isVoting,
    error
  } = trpc.propositions.voteOnProposition.useMutation();
  const voteProposition = async (vote: "YES" | "NO") => {
    try {
      const updatedProposition = await voteOnProposition({
        propositionId: proposition.id,
        vote: vote
      });
      const updatedPrediction = updatedProposition.prediction;
      setPrediction(updatedPrediction);
    } catch (error) {
      console.error("Error voting:", error);
    }
  };
  const updatePrediction = (newPrediction: Partial<Prediction>) => {
    setPrediction(prev => ({
      ...prev,
      ...newPrediction
    }));
  };
  return <PredictionCardContext.Provider value={{
    proposition,
    prediction,
    userLoggedIn,
    isVoting,
    voteProposition,
    updatePrediction
  }} data-sentry-element="unknown" data-sentry-component="PredictionCardProvider" data-sentry-source-file="PredictionCardProvider.tsx">
      {children}
    </PredictionCardContext.Provider>;
}
export function usePredictionCard() {
  const context = useContext(PredictionCardContext);
  if (context === undefined) {
    throw new Error("usePredictionCard must be used within a PredictionCardProvider");
  }
  return context;
}
"use client";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import dayjs from "dayjs";
import AuthWallDialog from "./AuthWallDialog";
import { EventDialog } from "./EventDialog";
import { useEventDialog } from "./EventDialogProvider";
export type PastEvent = {
  id: string;
  imageUrl: string;
  imageTitle: string;
  title: string;
  summary: string;
  impact: string;
  sources: {
    name: string;
    url: string;
  }[];
  date: string;
};
export default function HomePastEvent({
  index,
  event,
  userLoggedIn
}: {
  index: number;
  event: PastEvent;
  userLoggedIn: boolean;
}) {
  const {
    openEventDialog,
    setHoveredEventId
  } = useEventDialog();
  return <AuthWallDialog action="past_event_card_clicked" title="Create a free OnTrade account to get news analysis and impact" subtitle="OnTrade distills news to show event impacts on stocks, with chart overlays for clarity. Stay informed effortlessly." redirectUrl="/sign-up" data-sentry-element="AuthWallDialog" data-sentry-component="HomePastEvent" data-sentry-source-file="HomeEvents.tsx">
      <div>
        <EventDialog event={event} isAuthenticated={userLoggedIn} data-sentry-element="EventDialog" data-sentry-source-file="HomeEvents.tsx">
          <button className="flex flex-row gap-4 justify-start items-center text-left relative cursor-pointer hover:bg-secondary/10 p-2 rounded-lg transition-colors" onClick={() => {
          if (userLoggedIn) {
            openEventDialog(event);
          }
        }} onMouseEnter={() => setHoveredEventId(event.id)} onMouseLeave={() => setHoveredEventId(null)}>
            <div className="rounded-full bg-primary min-w-7 min-h-7 w-7 h-7 flex items-center justify-center text-base font-semibold text-white">
              {index}
            </div>
            <div className="flex flex-col flex-1 min-w-0">
              <h4 className="font-bold text-sm">{event.title}</h4>
              <p className="text-sm line-clamp-1">{event.summary}</p>
              <span className="text-sm">
                {dayjs(event.date).format("MM/DD/YY")}
              </span>
            </div>
          </button>
        </EventDialog>
      </div>
    </AuthWallDialog>;
}
export type FutureEvent = PastEvent;
export function HomeFutureEvent({
  index,
  event,
  userLoggedIn
}: {
  index: number;
  event: FutureEvent;
  userLoggedIn: boolean;
}) {
  return <AuthWallDialog action="future_event_card_clicked" title="Create a free OnTrade account to stay ahead of what's next." subtitle="OnTrade gives you a clear view of what's ahead for your stocks. Get alerts on key events likely to move the market." redirectUrl="/sign-up" data-sentry-element="AuthWallDialog" data-sentry-component="HomeFutureEvent" data-sentry-source-file="HomeEvents.tsx">
      <div className="flex flex-row gap-4 items-center relative cursor-pointer hover:bg-secondary/10 p-2 rounded-lg transition-colors">
        <Avatar className="w-11 h-11 rounded-lg" data-sentry-element="Avatar" data-sentry-source-file="HomeEvents.tsx">
          <AvatarImage src={event.imageUrl} data-sentry-element="AvatarImage" data-sentry-source-file="HomeEvents.tsx" />
          <AvatarFallback data-sentry-element="AvatarFallback" data-sentry-source-file="HomeEvents.tsx">{event.imageTitle}</AvatarFallback>
        </Avatar>
        <div className="flex flex-col flex-1 min-w-0">
          <h4 className="font-bold text-sm">{event.title}</h4>
          <p className="text-sm line-clamp-1">{event.summary}</p>
          <span className="text-sm">
            {dayjs(event.date).format("MM/DD/YY")}
          </span>
        </div>
      </div>
    </AuthWallDialog>;
}
"use client";

import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Prediction, Proposition } from "@/lib/ontrade";
import { cn } from "@/lib/utils";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import dayjs from "dayjs";
import { usePostHog } from "posthog-js/react";
import AuthWallDialog from "./AuthWallDialog";
import GaugeWithDetails from "./GaugeWithDetails";
import { PredictionCardGraph } from "./PredictionCardGraph";
import { PredictionCardProvider, usePredictionCard } from "./PredictionCardProvider";
import PredictionCardVoting from "./PredictionCardVoting";
export default function HomePredictionCard({
  proposition,
  prediction,
  userLoggedIn
}: {
  proposition: Proposition;
  prediction: Prediction;
  userLoggedIn: boolean;
}) {
  return <PredictionCardProvider initialProposition={proposition} initialPrediction={prediction} userLoggedIn={userLoggedIn} data-sentry-element="PredictionCardProvider" data-sentry-component="HomePredictionCard" data-sentry-source-file="HomePredictionCard.tsx">
      <HomePredictionCardContent data-sentry-element="HomePredictionCardContent" data-sentry-source-file="HomePredictionCard.tsx" />
    </PredictionCardProvider>;
}
function HomePredictionCardContent() {
  const {
    proposition,
    prediction,
    userLoggedIn
  } = usePredictionCard();
  const {
    proposition_hero_text,
    proposition_prefix,
    proposition_suffix
  } = proposition;
  const {
    num_votes_yes,
    num_votes_no,
    likelihood,
    user_predicted_value
  } = prediction;
  const hasVotes = num_votes_yes > 0 || num_votes_no > 0;
  // prevent division by 0
  const communityGaugeValue = hasVotes ? num_votes_yes / (num_votes_yes + num_votes_no) * 100 : 0;
  const propositionLikelihoodScore = likelihood.overall_likelihood ?? 0;
  const isVotingEnded = proposition.voting_status === "CANCELLED" || proposition.voting_status === "COMPLETED";
  const isPropositionResolved = proposition.proposition_outcome === "PROVEN_RIGHT" || proposition.proposition_outcome === "PROVEN_WRONG";
  const posthog = usePostHog();
  const captureGraphModalOpenClose = (open: boolean) => {
    if (!userLoggedIn) {
      return;
    }
    if (open) {
      posthog.capture("User opened proposition card", {
        proposition_id: proposition.id
      });
    } else {
      posthog.capture("User closed proposition card", {
        proposition_id: proposition.id
      });
    }
  };
  const totalVotes = num_votes_yes + num_votes_no;
  const voteWord = totalVotes === 1 ? "vote" : "votes";
  const votesLabel = `${totalVotes} ${voteWord}`;
  return <AuthWallDialog action="vote_card_clicked" title="Create a free OnTrade account to vote on this prediction" subtitle="Participate in voting, see how other votes, and unlock more when you sign up for OnTrade." data-sentry-element="AuthWallDialog" data-sentry-component="HomePredictionCardContent" data-sentry-source-file="HomePredictionCard.tsx">
      <div>
        <Dialog open={userLoggedIn ? undefined : false} onOpenChange={captureGraphModalOpenClose} data-sentry-element="Dialog" data-sentry-source-file="HomePredictionCard.tsx">
          <div className="rounded-xl bg-card w-full h-full max-w-full sm:max-w-[22rem] min-w-min flex flex-row gap-2 overflow-hidden border shadow-md hover:shadow-lg transition-shadow duration-200">
            <DialogTrigger className="flex basis-2/3 flex-col items-start p-4 pr-0 gap-2 text-left h-full" data-sentry-element="DialogTrigger" data-sentry-source-file="HomePredictionCard.tsx">
              <p className="font-semibold text-lg/[1.375rem]">
                {proposition_prefix}
              </p>
              <p className="text-5xl font-bold tracking-tight">
                {proposition_hero_text}
              </p>
              <p className="text-lg">{proposition_suffix}</p>
              <div className="flex flex-row justify-between w-full mt-auto h-full items-end">
                <div className="flex flex-row items-center">
                  <InfoCircledIcon className="mr-1 w-4 h-4" data-sentry-element="InfoCircledIcon" data-sentry-source-file="HomePredictionCard.tsx" />
                  <span className="text-xs">Details</span>
                </div>
                <span className={cn("text-xs mr-2", proposition.presentation_config.methodology.target !== "percentgain" && "hidden")}>
                  ($
                  {proposition.data.target_price ?? "N/A"} target)
                </span>
              </div>

              {/* hidden div with styles */}
              <div className="hidden h-[80vh] max-h-[80vh] md:flex-col w-26"></div>
            </DialogTrigger>
            <div className="flex flex-col basis-1/3 h-full">
              <DialogTrigger className={cn("flex-1 bg-secondary/10", isPropositionResolved && "hidden")} data-sentry-element="DialogTrigger" data-sentry-source-file="HomePredictionCard.tsx">
                <GaugeWithDetails gaugeValue={propositionLikelihoodScore} gaugeLabel="OnTrade" data-sentry-element="GaugeWithDetails" data-sentry-source-file="HomePredictionCard.tsx" />
              </DialogTrigger>
              <DialogTrigger className={cn("flex-1 bg-secondary/10 flex items-center justify-center flex-col basis-1/2", !isPropositionResolved && "hidden")} data-sentry-element="DialogTrigger" data-sentry-source-file="HomePredictionCard.tsx">
                <p className={cn("text-4xl font-bold", proposition.proposition_outcome === "PROVEN_RIGHT" ? "text-[#18A800]" : "text-[#E00F2F]")}>
                  {proposition.proposition_outcome === "PROVEN_RIGHT" ? "Yes" : "No"}
                </p>
                <p className="text-sm text-gray-500 mt-2">
                  {proposition.proposition_outcome === "PROVEN_RIGHT" ? "Fulfilled" : "Failed"}{" "}
                  {dayjs(proposition.voting_stopped_at).format("MMM D")}
                </p>
              </DialogTrigger>
              <hr className="border-t border-gray-200" />
              {(user_predicted_value === undefined || user_predicted_value === null) && !isVotingEnded && !isPropositionResolved ? <PredictionCardVoting userLoggedIn={userLoggedIn} className="bg-secondary/10" votingStatus={proposition.voting_status} voteFrom="card" /> : <DialogTrigger className="basis-1/2 bg-secondary/10">
                  <GaugeWithDetails gaugeValue={communityGaugeValue} gaugeLabel="Community" gaugeFooter={votesLabel} showExtremes={true} />
                </DialogTrigger>}
            </div>
          </div>
          <DialogContent className="max-w-2xl md:h-auto h-[80vh] max-h-[80vh] overflow-y-auto" data-sentry-element="DialogContent" data-sentry-source-file="HomePredictionCard.tsx">
            <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="HomePredictionCard.tsx">
              <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="HomePredictionCard.tsx">Prediction</DialogTitle>
            </DialogHeader>
            <PredictionCardGraph prediction={prediction} proposition={proposition} userLoggedIn={userLoggedIn} data-sentry-element="PredictionCardGraph" data-sentry-source-file="HomePredictionCard.tsx" />
          </DialogContent>
        </Dialog>
      </div>
    </AuthWallDialog>;
}
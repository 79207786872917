"use client";

import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/lib/utils";
import { trpc } from "@/providers/TrpcProvider";
import { useUser } from "@clerk/nextjs";
import dayjs from "dayjs";
import { Loader2, Triangle } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import React, { useMemo } from "react";
import { Cell, Pie, PieChart } from "recharts";
import AuthWallDialog from "./AuthWallDialog";
import { useCurrentPrice } from "./CurrentPriceProvider";
import { useHomeScenario } from "./HomeScenarioProvider";
import PotentialValueSlider from "./PotentialValueSlider";
import { getDialColor } from "./ScenarioCard";
import { useScenarioSlider } from "./ScenarioSliderProvider";
export default function HomeScenarioCard() {
  const router = useRouter();
  const {
    isSignedIn
  } = useUser();
  const {
    rsu,
    lastMarketOpenPrice,
    ticker,
    valueMode,
    setValueMode
  } = useHomeScenario();

  // Get slider-related state directly from ScenarioSliderProvider
  const {
    scenarioSelectedValue,
    defaultPrice,
    slideSteps,
    navigationIndex,
    priceLikelihood,
    priceLikelihoods,
    defaultIndex,
    isPriceLikelihoodLoading
  } = useScenarioSlider();
  const {
    currentPrice
  } = useCurrentPrice();

  // Fetch salary and upcoming vestings data for total comp calculation
  const {
    data: salary
  } = trpc.compensation.getSalaryByUserId.useQuery();
  const {
    data: grants
  } = trpc.rsu.getRsuGrants.useQuery(undefined, {
    initialData: []
  });
  const pastVestedRsus = grants?.length > 0 ? grants.flatMap(grant => grant.vestings).filter(vesting => vesting?.vestingDate && dayjs(vesting.vestingDate).isBefore(dayjs())) : [];
  const ownedRsus = pastVestedRsus.reduce((acc, vesting) => acc + (vesting?.ownedAmount ?? 0), 0);
  const rsuAmount = pastVestedRsus.length > 0 ? ownedRsus : rsu?.ownedRsu || 0;
  const priceToShow = scenarioSelectedValue;
  const currentPriceValue = defaultPrice;
  const changeDifference = (priceToShow - currentPriceValue) / currentPriceValue;
  const changeDifferenceValue = changeDifference;
  const totalValueDifference = priceToShow * rsuAmount - currentPrice * rsuAmount;

  // Calculate total compensation values
  const compValue: number = salary?.annualAmount ?? 0;
  const totalVestingValue: number = grants?.length > 0 ? grants.flatMap(grant => grant.vestings).filter(vesting => vesting?.vestingDate && dayjs(vesting.vestingDate).isAfter(dayjs().endOf("year").subtract(1, "year")) && dayjs(vesting.vestingDate).isBefore(dayjs().startOf("year").add(1, "year"))).reduce((acc, vesting) => acc + (vesting?.vestingAmount ?? 0), 0) : 0;

  // Calculate total comp value using scenario price
  const totalCompValue = compValue + totalVestingValue * scenarioSelectedValue;

  // Calculate the difference between scenario and current price total comp
  const currentTotalCompValue = compValue + totalVestingValue * defaultPrice;
  const totalCompDifference = totalCompValue - currentTotalCompValue;
  const compChangePercentage = totalCompDifference / currentTotalCompValue;
  const data = [{
    name: "likelihood",
    value: priceLikelihood
  }, {
    name: "not likelihood",
    value: 1 - priceLikelihood
  }];
  const tableSteps = useMemo(() => {
    if (!priceLikelihoods) return [];
    const twoPercentAbove = priceLikelihoods.find(likelihood => likelihood.step >= (priceLikelihoods?.[defaultIndex]?.step || 0) * 1.015);
    const fivePercentAbove = priceLikelihoods.filter(likelihood => likelihood.step !== twoPercentAbove?.step).find(likelihood => likelihood.step >= (priceLikelihoods?.[defaultIndex]?.step || 0) * 1.045 && twoPercentAbove?.step !== likelihood.step);
    const fifteenPercentAbove = priceLikelihoods.filter(step => step !== fivePercentAbove && step !== twoPercentAbove).find(likelihood => likelihood.step >= (priceLikelihoods?.[defaultIndex]?.step || 0) * 1.14 && fivePercentAbove?.step !== likelihood.step);
    const fivePercentBelow = priceLikelihoods.findLast(likelihood => likelihood.step <= (priceLikelihoods?.[defaultIndex]?.step || 0) * 0.96);
    return [fivePercentBelow, twoPercentAbove, fivePercentAbove, fifteenPercentAbove].filter(step => step !== undefined);
  }, [priceLikelihoods, defaultIndex]);
  return <div className={cn("w-full h-full grow rounded-xl bg-primary bg-gradient-to-b from-primary to-secondary/20 shadow-sm", "flex flex-col p-4 gap-4 shadow-md justify-between")} data-sentry-component="HomeScenarioCard" data-sentry-source-file="HomeScenarioCard.tsx">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row justify-between">
          <p className="font-semibold text-primary-foreground">AI Scenarios</p>
          {rsu && <div className="flex flex-row items-center gap-1">
              <Label htmlFor="valueMode" className="text-xs text-primary-foreground">
                RSUs
              </Label>
              <Switch id="valueMode" checked={valueMode} onCheckedChange={setValueMode} className="border border-muted data-[state=unchecked]:bg-primary" />
              <Label htmlFor="valueMode" className="text-xs text-primary-foreground">
                Total Comp
              </Label>
            </div>}
        </div>
        <div className="flex flex-row justify-between">
          <div>
            <div className="font-semibold text-xs text-secondary">
              SCENARIO PRICE
            </div>
            {isPriceLikelihoodLoading ? <>
                <div className="bg-secondary/10 rounded-md p-2 w-32 h-14 mt-2" />
              </> : <>
                <p className="text-3xl text-primary-foreground">
                  {priceToShow.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2
              })}{" "}
                </p>

                <p className={cn("text-muted-foreground font-medium flex flex-row items-center gap-1 mt-1", changeDifferenceValue > 0 ? "text-green-400" : "text-red-400", changeDifferenceValue === 0 ? "text-muted-foreground" : "")}>
                  {changeDifferenceValue !== 0 && <Triangle className={cn("w-3 h-3", changeDifferenceValue > 0 ? "text-green-400 fill-green-400" : "text-red-400 fill-red-400 rotate-180")} />}
                  {changeDifferenceValue.toLocaleString("en-US", {
                style: "percent",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
                </p>
              </>}
          </div>
          {rsu ? <div className="text-right">
              <div className="font-semibold text-xs text-secondary">
                {valueMode ? "SCENARIO TOTAL COMP" : "SCENARIO RSU VALUE"}
              </div>
              {isPriceLikelihoodLoading ? <div className="bg-secondary/10 rounded-md p-2 w-32 h-14 mt-2" /> : <>
                  <p className="text-3xl text-primary-foreground">
                    {valueMode ? totalCompValue.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0
              }) : (priceToShow * rsuAmount).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0
              })}
                  </p>

                  <p className={cn("text-muted-foreground font-medium flex flex-row-reverse items-center gap-1 mt-1", valueMode ? totalCompDifference > 0 ? "text-green-400" : "text-red-400" : totalValueDifference > 0 ? "text-green-400" : "text-red-400", (valueMode ? totalCompDifference : totalValueDifference) === 0 ? "text-muted-foreground" : "")}>
                    {valueMode ? totalCompDifference.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }) : totalValueDifference.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
                    {(valueMode ? totalCompDifference : totalValueDifference) !== 0 && <Triangle className={cn("w-3 h-3", valueMode ? totalCompDifference > 0 ? "text-green-400 fill-green-400" : "text-red-400 fill-red-400 rotate-180" : totalValueDifference > 0 ? "text-green-400 fill-green-400" : "text-red-400 fill-red-400 rotate-180")} />}
                  </p>
                </>}
            </div> : <div className="text-right text-secondary">
              <div className="font-semibold text-xs text-secondary">
                SCENARIO RSU VALUE
              </div>
              <Link href="/sign-up" className="text-3xl underline">
                Join
              </Link>
              <p>to see yours</p>
            </div>}
        </div>

        <div className="flex flex-col items-center -mt-10">
          <div className="relative">
            <PieChart width={240} height={120} className="-ml-3" data-sentry-element="PieChart" data-sentry-source-file="HomeScenarioCard.tsx">
              <Pie data={data} cx={120} cy={120} startAngle={180} endAngle={0} innerRadius={60} outerRadius={80} stroke="transparent" paddingAngle={0} dataKey="value" animationDuration={0} data-sentry-element="Pie" data-sentry-source-file="HomeScenarioCard.tsx">
                <Cell fill={getDialColor(data[0].value, "dark")} data-sentry-element="Cell" data-sentry-source-file="HomeScenarioCard.tsx" />
                <Cell fill={"#FFFFFF"} data-sentry-element="Cell" data-sentry-source-file="HomeScenarioCard.tsx" />
              </Pie>
            </PieChart>
            {isPriceLikelihoodLoading && !data[0].value ? <div className="h-10 absolute bottom-0 text-center left-0 right-0">
                <div className="flex justify-center items-center">
                  <Image src="/ai-sparkles-ani.svg" alt="Loading" width={40} height={40} />
                </div>
              </div> : <div className="text-4xl text-primary-foreground absolute -bottom-4 text-center left-0 right-0 font-semibold">
                {/* TODO: margin is uncertain here */}
                <span className="tracking-tighter w-full">
                  {data[0].value.toLocaleString("en-US", {
                style: "percent",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
                </span>
              </div>}
          </div>
          <p className="text-sm text-primary-foreground pt-4 font-semibold">
            IN THE NEXT 90D
          </p>
        </div>
        <PotentialValueSlider data-sentry-element="PotentialValueSlider" data-sentry-source-file="HomeScenarioCard.tsx" />
        <AuthWallDialog action="scenario_card_clicked" title="Create a free OnTrade account to explore Scenarios in depth" subtitle="Set a price, and our proprietary AI shows how likely it is and what it'll take to get there. Stop speculating - start strategizing with exclusive insights." redirectUrl={`/scenario?ticker=${ticker}&initialIndex=${navigationIndex}`} data-sentry-element="AuthWallDialog" data-sentry-source-file="HomeScenarioCard.tsx">
          <button onClick={() => {
          if (isSignedIn) {
            router.push(`/scenario?ticker=${ticker}&initialIndex=${navigationIndex}`);
          }
        }} className={cn("text-center py-2", "text-sm text-secondary font-semibold border border-secondary rounded-md mx-8 border-b-2 transition-all duration-300", "bg-[linear-gradient(180deg,rgba(255,255,255,0.13)_0%,rgba(255,255,255,0.00)_100%)]", "hover:bg-[linear-gradient(180deg,rgba(255,255,255,0.2)_0%,rgba(255,255,255,0.00)_100%)] hover:text-secondary")}>
            Explore this scenario
          </button>
        </AuthWallDialog>
      </div>
      <div className="grid grid-cols-10 gap-4">
        <div className="col-span-4 text-secondary uppercase font-semibold">
          Price
        </div>
        <div className="col-span-3 text-center text-secondary uppercase font-semibold">
          Likelihood
        </div>
        <div className="col-span-3 text-right text-secondary uppercase font-semibold">
          Value
        </div>
        {isPriceLikelihoodLoading && !tableSteps.length && <div className="h-36 col-span-10 flex flex-col items-center justify-center">
            <Loader2 className="animate-spin text-primary-foreground" />
            <p className="text-sm text-primary-foreground">Loading...</p>
          </div>}
        {tableSteps.map(step => {
        const defaultStep = slideSteps?.steps[slideSteps?.defaultIndex];
        const defaultStepValue = defaultStep || 0;
        const stepDifference = step.step - defaultStepValue;
        const stepDifferencePercentage = stepDifference / defaultStepValue * 100;

        // Calculate value based on mode (RSU value or total comp)
        const rsuValue = step.step * rsuAmount;
        const totalVestingValue = grants?.flatMap(grant => grant.vestings)?.filter(vesting => vesting?.vestingDate && dayjs(vesting.vestingDate).isAfter(dayjs().endOf("year").subtract(1, "year")) && dayjs(vesting.vestingDate).isBefore(dayjs().startOf("year").add(1, "year"))).reduce((acc, vesting) => acc + (vesting?.vestingAmount ?? 0), 0) ?? 0;
        const scenarioCompValue = (compValue || 0) + totalVestingValue * step.step;
        const valueToShow = valueMode ? scenarioCompValue : rsuValue;
        return <React.Fragment key={step.step}>
              <div key={step.step + "price"} className={cn("col-span-4 font-semibold", step.step > defaultStepValue ? "text-green-400" : "text-red-400")}>
                {Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: step.step > 100 ? 0 : step.step > 10 ? 1 : 2,
              maximumFractionDigits: step.step > 100 ? 0 : step.step > 10 ? 1 : 2
            }).format(step.step)}{" "}
                (
                {(stepDifferencePercentage / 100).toLocaleString("en-US", {
              style: "percent",
              signDisplay: "always",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
                )
              </div>
              <div key={step + "likelihood"} className="col-span-3 text-center text-primary-foreground font-semibold">
                {priceLikelihoods.find(likelihood => likelihood.step === step.step)?.likelihood.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
              </div>
              <div key={step + "value"} className="col-span-3 text-right text-primary-foreground font-semibold">
                {Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }).format(valueToShow)}
              </div>
            </React.Fragment>;
      })}
      </div>
    </div>;
}
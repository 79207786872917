"use client";

import { cn } from "@/lib/utils";
import { ChartDataContext } from "../stock/_components/ChartDataProvider";
import { useContext } from "react";
const periodButtons = [{
  period: "day",
  label: "1D"
}, {
  period: "week",
  label: "5D"
}, {
  period: "month",
  label: "1M"
}, {
  period: "6M",
  label: "6M"
}, {
  period: "ytd",
  label: "YTD"
}, {
  period: "year",
  label: "1Y"
}];
export function HomeStockPriceButtons() {
  const {
    period,
    setPeriod
  } = useContext(ChartDataContext);
  return <div className="flex max-w-full justify-between" data-sentry-component="HomeStockPriceButtons" data-sentry-source-file="HomeStockPriceButtons.tsx">
      {periodButtons.map(button => <button key={button.period} onClick={() => setPeriod(button.period as any)} className={cn("text-xs rounded-xl w-11 h-11", button.period === period && "bg-gray-200 font-bold")}>
          {button.label}
        </button>)}
    </div>;
}
"use client";

import { RsuBasicRecord } from "@/db/schema";
import { trpc } from "@/providers/TrpcProvider";
import { GetSliderStepsReturnType } from "@/server/providers/scenarios";
import { createContext, ReactNode, useContext, useState } from "react";
import ScenarioSliderProvider from "./ScenarioSliderProvider";

// Define the context type with only the values needed by HomeScenarioCard
interface HomeScenarioContextType {
  rsu: RsuBasicRecord | null;
  ticker: string;
  lastMarketOpenPrice: number | undefined | null;
  vestedOnly: boolean;
  setVestedOnly: (vestedOnly: boolean) => void;
  valueMode: boolean;
  setValueMode: (valueMode: boolean) => void;
}

// Create the context
const HomeScenarioContext = createContext<HomeScenarioContextType>({} as HomeScenarioContextType);

// Create a hook to use the context
export const useHomeScenario = () => useContext(HomeScenarioContext);

// Define the props for the HomeScenarioProvider
interface HomeScenarioProviderProps {
  children: ReactNode;
  ticker: string;
  defaultRsu: RsuBasicRecord | null;
  defaultSteps?: GetSliderStepsReturnType;
  lastScenarioIndex?: number;
}

// Create the HomeScenarioProvider component
export default function HomeScenarioProvider({
  children,
  ticker,
  defaultRsu,
  defaultSteps,
  lastScenarioIndex
}: HomeScenarioProviderProps) {
  // Use the ScenarioSliderProvider for slider-related state
  return <ScenarioSliderProvider ticker={ticker} defaultSteps={defaultSteps} lastScenarioIndex={lastScenarioIndex} preserveIndex skipDefaultIndex forceInitialIndex data-sentry-element="ScenarioSliderProvider" data-sentry-component="HomeScenarioProvider" data-sentry-source-file="HomeScenarioProvider.tsx">
      <HomeScenarioProviderInner defaultRsu={defaultRsu} ticker={ticker} data-sentry-element="HomeScenarioProviderInner" data-sentry-source-file="HomeScenarioProvider.tsx">
        {children}
      </HomeScenarioProviderInner>
    </ScenarioSliderProvider>;
}

// Inner component that uses the ScenarioSliderProvider
function HomeScenarioProviderInner({
  children,
  defaultRsu,
  ticker
}: {
  children: ReactNode;
  defaultRsu: RsuBasicRecord | null;
  ticker: string;
}) {
  const {
    data: lastMarketOpenPrice
  } = trpc.tickers.getPrevMarketDayClosePrice.useQuery({
    ticker
  });
  const [vestedOnly, setVestedOnly] = useState(true);
  const [valueMode, setValueMode] = useState(false);

  // Provide the context value with only the values needed by HomeScenarioCard
  const contextValue: HomeScenarioContextType = {
    rsu: defaultRsu,
    ticker,
    lastMarketOpenPrice,
    vestedOnly,
    setVestedOnly,
    valueMode,
    setValueMode
  };
  return <HomeScenarioContext.Provider value={contextValue} data-sentry-element="unknown" data-sentry-component="HomeScenarioProviderInner" data-sentry-source-file="HomeScenarioProvider.tsx">
      {children}
    </HomeScenarioContext.Provider>;
}
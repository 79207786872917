"use client";

import { buttonVariants } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/lib/utils";
import { trpc } from "@/providers/TrpcProvider";
import { GetRsuGrantsReturnType, GetRsusReturnType } from "@/server/providers/rsus";
import { useUser } from "@clerk/nextjs";
import dayjs from "dayjs";
import { Loader2, Triangle } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { Textfit } from "react-textfit";
import AuthWallDialog from "./AuthWallDialog";
import { useCurrentPrice } from "./CurrentPriceProvider";
export default function HomeStockPriceCard({
  tickerToQuery
}: {
  tickerToQuery: string;
}) {
  const {
    currentPrice,
    lastMarketDayClosePrice
  } = useCurrentPrice();
  const {
    data: marketStatus
  } = trpc.tickers.getMarketStatus.useQuery();
  const lastMarketOpenPriceValue = lastMarketDayClosePrice || currentPrice;
  const lastMarketOpenPriceChangeDifference = (currentPrice - lastMarketOpenPriceValue) / lastMarketOpenPriceValue;
  const lastMarketDay = dayjs(marketStatus?.lastMarketDay);
  const isToday = dayjs().isSame(lastMarketDay, "day");
  const isYesterday = dayjs().subtract(1, "day").isSame(lastMarketDay, "day");
  let changeDate = dayjs().format("MMM DD");
  if (isToday || marketStatus?.isAfterHours || marketStatus?.isMarketOpen) {
    changeDate = "Today";
  } else if (isYesterday) {
    changeDate = "Yesterday";
  } else {
    changeDate = lastMarketDay.format("MMM DD");
  }
  return <Link href={`/stock/${tickerToQuery}`} className={cn("w-full bg-card rounded-xl p-4 border border-border shadow-md h-full flex flex-col justify-between", "hover:shadow-lg transition-shadow duration-200")} data-sentry-element="Link" data-sentry-component="HomeStockPriceCard" data-sentry-source-file="HomeStockPriceCard.tsx">
      <div className="flex flex-col gap-4 w-full">
        <div className="font-semibold text-sm">
          Current {tickerToQuery} Price
        </div>
        <div className="flex flex-col items-center gap-1">
          <p className="text-5xl font-bold text-center">
            {currentPrice.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2
          })}
          </p>

          <p className={cn("text-muted-foreground font-semibold flex flex-row items-center gap-1 text-sm", lastMarketOpenPriceChangeDifference > 0 ? "text-green-600" : "text-red-600", lastMarketOpenPriceChangeDifference === 0 ? "text-muted-foreground" : "")}>
            {lastMarketOpenPriceChangeDifference !== 0 && <Triangle className={cn("w-2 h-2", lastMarketOpenPriceChangeDifference > 0 ? "text-green-600 fill-green-600" : "text-red-600 fill-red-600 rotate-180")} />}
            {lastMarketOpenPriceChangeDifference.toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}{" "}
            <span>{changeDate}</span>
            <span className={cn(lastMarketOpenPriceChangeDifference > 0 ? "text-green-600" : "text-red-600", lastMarketOpenPriceChangeDifference === 0 && "text-muted-foreground")}></span>
          </p>
        </div>
      </div>
      <div className={cn(buttonVariants({
      variant: "ghost",
      size: "sm"
    }), "w-full")}>
        See {tickerToQuery} Details
      </div>
    </Link>;
}
export function HomeRSUValueCard({
  rsu: defaultRsu,
  grants: defaultGrants,
  tickerToQuery
}: {
  rsu: GetRsusReturnType | null;
  grants: GetRsuGrantsReturnType | null;
  tickerToQuery: string;
}) {
  const [isOwnedOnly, setIsOwnedOnly] = useState(true);
  const {
    data: rsu
  } = trpc.rsu.getRsus.useQuery(undefined, {
    initialData: defaultRsu ?? undefined
  });
  const {
    data: grants
  } = trpc.rsu.getRsuGrants.useQuery(undefined, {
    initialData: defaultGrants ?? undefined
  });
  const {
    currentPrice,
    lastMarketDayClosePrice
  } = useCurrentPrice();
  const rsuAmount = (() => {
    if (grants && grants.length > 0) {
      if (isOwnedOnly) {
        return grants.flatMap(grant => grant.vestings).filter(vesting => vesting?.vestingDate && dayjs(vesting?.vestingDate).isBefore(dayjs())).reduce((acc, vesting) => acc + (vesting?.ownedAmount || 0), 0);
      } else {
        return grants.flatMap(grant => grant.vestings).reduce((acc, vesting) => acc + (vesting?.vestingAmount || 0), 0);
      }
    } else if (rsu) {
      if (isOwnedOnly) {
        return rsu.ownedRsu || 0;
      } else {
        return rsu.totalRsu || 0;
      }
    }
    return 0;
  })();
  const lastMarketOpenPriceValue = lastMarketDayClosePrice || currentPrice;
  const totalValueDifference = currentPrice * rsuAmount - lastMarketOpenPriceValue * rsuAmount;
  const router = useRouter();
  const {
    isSignedIn
  } = useUser();
  return <AuthWallDialog action="rsu_value_card_clicked" title="Create a free OnTrade account to manage your holdings" subtitle="Whether it's RSUs or shares, OnTrade tracks your investments value and schedule effortlessly" redirectUrl="/settings/grants" data-sentry-element="AuthWallDialog" data-sentry-component="HomeRSUValueCard" data-sentry-source-file="HomeStockPriceCard.tsx">
      <button onClick={() => {
      if (isSignedIn) {
        router.push("/settings/grants");
      }
    }} className={cn("flex flex-col justify-between gap-4 w-full bg-card rounded-xl p-4 border border-border shadow-md h-full", "hover:shadow-lg transition-shadow duration-200")}>
        <div className="flex flex-col gap-4 w-full">
          <div className="flex flex-row items-start justify-between gap-4">
            <div className="font-semibold text-sm">
              Your {tickerToQuery} RSUs
            </div>
            <div className="flex flex-row items-center gap-1">
              <Label htmlFor="OwnedOnly" className="text-xs text-muted-foreground" data-sentry-element="Label" data-sentry-source-file="HomeStockPriceCard.tsx">
                Granted
              </Label>
              <Switch id="OwnedOnly" checked={isOwnedOnly} className="data-[state=unchecked]:bg-primary" onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              setIsOwnedOnly(!isOwnedOnly);
            }} data-sentry-element="Switch" data-sentry-source-file="HomeStockPriceCard.tsx" />
              <Label htmlFor="OwnedOnly" className="text-xs text-muted-foreground" data-sentry-element="Label" data-sentry-source-file="HomeStockPriceCard.tsx">
                Held
              </Label>
            </div>
          </div>

          <div className="w-full">
            <Textfit max={48} mode="single" className="font-bold w-full text-center -mt-[7px] -mb-[7px]" data-sentry-element="Textfit" data-sentry-source-file="HomeStockPriceCard.tsx">
              {(currentPrice * rsuAmount).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0
            })}
            </Textfit>

            <p className={cn("text-muted-foreground font-semibold flex items-center gap-1 text-sm justify-center", totalValueDifference > 0 ? "text-green-600" : "text-red-600", totalValueDifference === 0 ? "text-muted-foreground" : "")}>
              {totalValueDifference !== 0 && <Triangle className={cn("w-2 h-2", totalValueDifference > 0 ? "text-green-600 fill-green-600" : "text-red-600 fill-red-600 rotate-180")} />}
              {totalValueDifference.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
              <span>Today</span>
            </p>
          </div>
        </div>

        <div className="flex flex-row items-center gap-4 justify-center w-full">
          <div className={cn(buttonVariants({
          variant: "ghost",
          size: "sm"
        }), "w-full")}>
            Edit RSUs
          </div>
        </div>
      </button>
    </AuthWallDialog>;
}
export function TotalCompValueCard({
  isAuthed
}: {
  isAuthed: boolean;
}) {
  const {
    currentPrice
  } = useCurrentPrice();
  const {
    data: salary,
    isLoading
  } = trpc.compensation.getSalaryByUserId.useQuery(undefined, {
    enabled: isAuthed,
    trpc: {
      context: {
        skipBatch: true
      }
    }
  });
  const {
    data: grants
  } = trpc.rsu.getRsuGrants.useQuery(undefined, {
    initialData: [],
    enabled: !!salary && isAuthed,
    trpc: {
      context: {
        skipBatch: true
      }
    }
  });
  const compValue: number = salary?.annualAmount ?? 0;
  const currentYear = dayjs().year();
  const totalVestingValue: number = grants?.length > 0 ? grants.flatMap(grant => grant.vestings).filter(vesting => vesting?.vestingDate && dayjs(vesting.vestingDate).isAfter(dayjs().endOf("year").subtract(1, "year")) && dayjs(vesting.vestingDate).isBefore(dayjs().startOf("year").add(1, "year"))).reduce((acc, vesting) => acc + (vesting?.vestingAmount ?? 0), 0) : 0;
  const totalCompValue = compValue + totalVestingValue * currentPrice;
  if (!isAuthed) {
    return <AuthWallDialog action="total_comp_value_card_clicked" title="Sign up to track your total compensation" subtitle="Track how much you'll earn in real-time" redirectUrl="/settings/salary">
        <div className={cn("flex flex-col justify-between gap-4 w-full bg-card rounded-xl p-4 border border-border shadow-md h-full", "hover:shadow-lg transition-shadow duration-200")}>
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-sm">
              Your Total {currentYear} Compensation
            </div>
            <h3 className="text-4xl font-bold text-center">Sign up</h3>
            <p className="text-green-600 font-medium px-8 text-center">
              Track how much you&apos;ll earn in real-time
            </p>
          </div>

          <div className="flex flex-row items-center justify-center">
            <div className={cn(buttonVariants({
            variant: "ghost",
            size: "sm"
          }), "w-full")}>
              See more
            </div>
          </div>
        </div>
      </AuthWallDialog>;
  }
  return <Link href="/settings/salary" className={cn("flex flex-col justify-between gap-4 w-full bg-card rounded-xl p-4 border border-border shadow-md h-full", "hover:shadow-lg transition-shadow duration-200")} data-sentry-element="Link" data-sentry-component="TotalCompValueCard" data-sentry-source-file="HomeStockPriceCard.tsx">
      <div className="flex flex-col gap-4 w-full">
        <div className="flex flex-row items-start justify-between gap-4">
          <div className="font-semibold text-sm">
            Your Total {currentYear} Compensation
          </div>
        </div>

        <div className="w-full">
          {isLoading ? <div className="flex flex-row items-center justify-center">
              <Loader2 className="w-8 h-8 animate-spin" />
            </div> : <Textfit max={48} mode="single" className="font-bold w-full text-center -mt-[7px] -mb-[7px]">
              {totalCompValue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0
          })}
            </Textfit>}
        </div>
      </div>

      <div className="flex flex-row items-center justify-center">
        <div className={cn(buttonVariants({
        variant: "ghost",
        size: "sm"
      }), "w-full")}>
          Edit Salary
        </div>
      </div>
    </Link>;
}
export function HomeStockPriceCardLegacy({
  rsu: defaultRsu,
  firstName
}: {
  rsu: GetRsusReturnType | null;
  firstName: string | null;
}) {
  const [isVestedOnly, setIsVestedOnly] = useState(false);
  const {
    currentPrice,
    lastMarketDayClosePrice
  } = useCurrentPrice();
  const {
    data: rsu
  } = trpc.rsu.getRsus.useQuery(undefined, {
    initialData: defaultRsu ?? undefined
  });
  const {
    data: marketStatus
  } = trpc.tickers.getMarketStatus.useQuery();
  const rsuAmount = (isVestedOnly ? rsu?.vestedRsu : rsu?.totalRsu) || 0;
  const lastMarketOpenPriceValue = lastMarketDayClosePrice || currentPrice;
  const lastMarketOpenPriceChangeDifference = (currentPrice - lastMarketOpenPriceValue) / lastMarketOpenPriceValue;
  const totalValueDifference = currentPrice * rsuAmount - lastMarketOpenPriceValue * rsuAmount;
  const lastMarketDay = dayjs(marketStatus?.lastMarketDay);
  const isToday = dayjs().isSame(lastMarketDay, "day");
  const isYesterday = dayjs().subtract(1, "day").isSame(lastMarketDay, "day");
  let changeDate = dayjs().format("MMM DD");
  if (isToday || marketStatus?.isAfterHours || marketStatus?.isMarketOpen) {
    changeDate = "Today";
  } else if (isYesterday) {
    changeDate = "Yesterday";
  } else {
    changeDate = lastMarketDay.format("MMM DD");
  }
  return <div className="flex flex-col p-4 max-w-screen-lg w-full grow mx-auto gap-4" data-sentry-component="HomeStockPriceCardLegacy" data-sentry-source-file="HomeStockPriceCard.tsx">
      <div className="flex flex-row justify-between">
        <p className="text-xl">
          {firstName ? `Hello, ${firstName}` : "Welcome to OnTrade"}
        </p>
        {rsu && <div className="flex flex-row items-center gap-1">
            <Switch id="vestedOnly" checked={isVestedOnly} onCheckedChange={setIsVestedOnly} />
            <Label htmlFor="vestedOnly" className="text-xs text-muted-foreground">
              Vested only
            </Label>
          </div>}
      </div>

      <div className="flex flex-row justify-between">
        <div>
          <div className="font-semibold text-xs text-muted-foreground">
            CURRENT PRICE
          </div>
          <p className="text-3xl">
            {currentPrice.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2
          })}
          </p>

          <p className={cn("text-muted-foreground font-medium flex flex-row items-center gap-1 mt-1", lastMarketOpenPriceChangeDifference > 0 ? "text-green-600" : "text-red-600", lastMarketOpenPriceChangeDifference === 0 ? "text-muted-foreground" : "")}>
            {lastMarketOpenPriceChangeDifference !== 0 && <Triangle className={cn("w-3 h-3", lastMarketOpenPriceChangeDifference > 0 ? "text-green-600 fill-green-600" : "text-red-600 fill-red-600 rotate-180")} />}
            {lastMarketOpenPriceChangeDifference.toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}{" "}
            <span className="text-sm">{changeDate}</span>
            <span className={cn("text-xs", lastMarketOpenPriceChangeDifference > 0 ? "text-green-600" : "text-red-600", lastMarketOpenPriceChangeDifference === 0 && "text-muted-foreground")}></span>
          </p>
        </div>
        {rsu ? <div className="text-right">
            <div className="font-semibold text-xs text-muted-foreground">
              YOUR{" "}
              {rsuAmount.toLocaleString("en-US", {
            maximumFractionDigits: 0
          })}{" "}
              RSUS
            </div>
            <p className="text-3xl ">
              {(currentPrice * rsuAmount).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0
          })}
            </p>

            <p className={cn("text-muted-foreground font-medium flex flex-row-reverse items-center gap-1 mt-1", totalValueDifference > 0 ? "text-green-600" : "text-red-600", totalValueDifference === 0 ? "text-muted-foreground" : "")}>
              {totalValueDifference.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })}
              {totalValueDifference !== 0 && <Triangle className={cn("w-3 h-3", totalValueDifference > 0 ? "text-green-600 fill-green-600" : "text-red-600 fill-red-600 rotate-180")} />}
            </p>
          </div> : <div className="text-right">
            <div className="font-semibold text-xs text-muted-foreground">
              YOUR RSU VALUE
            </div>
            <Link href="/sign-up" className="text-3xl underline text-primary">
              Join
            </Link>
            <p className={cn("text-primary font-medium flex flex-row-reverse items-center gap-1 mt-1")}>
              to add yours
            </p>
          </div>}
      </div>
    </div>;
}